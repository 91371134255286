html {
  --scrollbarBG: #587b8a;
  --thumbBG: #142933;
}

body {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  width: 13px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari/Edge */
  /*styles here*/
  color: rgb(100, 23, 23);
}

*::-ms-input-placeholder {
  /* Microsoft Edge */
  /*styles here*/
  color: white;
}
